/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/react@15.6.1/dist/react.js
 * - /npm/react-dom@15.6.1/dist/react-dom.min.js
 * - /npm/prop-types@15.5.10/prop-types.min.js
 * - /npm/mobx@3.3.0/lib/mobx.umd.min.js
 * - /npm/mobx-react@4.3.2/index.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
